<template>
	<div class="err">
        <div class="pagenotfound" v-if="error?.statusCode == 404">
            <h1 class="icon"><i class="fas fa-link-slash"></i></h1>
            <h1>De pagina die u zocht is niet gevonden</h1>
            <h3>
                De pagina <code>{{ error.data?.path ?? '?' }}</code> werd niet gevonden.
            </h3>
            <p>
                Maar niet getreurd! Er is nog zo veel lekkers te ontdekken!
            </p>
        </div>
        <div class="genericerror" v-else>
            <h1 class="icon"><i class="fas fa-bug"></i></h1>
            <h1>Oeps! Er heeft zich een error voorgedaan!</h1>
            <h3>
                Geen zorgen, dit is waarschijnlijk een bug. <br />
                We doen ons best om deze zo snel mogelijk te verhelpen.
            </h3>
            <code>{{ error }}</code>
        </div>
		<button
			class="btn btn-success btn-outline mt-2"
			@click="handleError"
		>
			<i class="fas fa-home"></i>&ensp;Terug naar de homepagina
		</button>
	</div>
</template>

<script lang="ts" setup>
import type { NuxtError } from "#app";

const props = defineProps({
	error: Object as () => NuxtError,
});

const handleError = () => clearError({ redirect: "/" });
</script>

<style lang="scss" scoped>
.err {
	width: 100vw;
	height: 100vh;

	position: absolute;
	top: 0;
	left: 0;
	z-index: 50;

	display: grid;
	place-content: center;
	background-color: #fff;

	font-family: "Lexend", sans-serif;
	text-align: center;

	.icon {
		color: var(--error-main);
		font-size: 4rem;
		margin-bottom: 0;
	}

	.close{
		position: absolute;
		top: 1rem;
		right: 1rem;
	}
}
</style>