
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as account_deletedWxsI3L9xjwMeta } from "/home/runner/work/Macabram/Macabram/pages/account_deleted.vue?macro=true";
import { default as categoriesmBYuPkUrs5Meta } from "/home/runner/work/Macabram/Macabram/pages/admin/categories.vue?macro=true";
import { default as index_46clientJPc2UrgPfFMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/runner/work/Macabram/Macabram/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_93RYZhOh5yr0Meta } from "/home/runner/work/Macabram/Macabram/pages/admin/order/[id].vue?macro=true";
import { default as completepyMcAqyl79Meta } from "/home/runner/work/Macabram/Macabram/pages/admin/order/manual/complete.vue?macro=true";
import { default as indexbMzCvVSdaHMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/order/manual/index.vue?macro=true";
import { default as ordersFKniD9wjbwMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/orders.vue?macro=true";
import { default as productsHtfcqoCu0IMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/products.vue?macro=true";
import { default as settings_46clientGljYY8oPqVMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/settings.client.vue?macro=true";
import { default as usersBWObglUq0IMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/users.vue?macro=true";
import { default as variationsk1BuqBENiSMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/variations.vue?macro=true";
import { default as adminkFAv9K5WigMeta } from "/home/runner/work/Macabram/Macabram/pages/admin.vue?macro=true";
import { default as homeUrYHCwEvyyMeta } from "/home/runner/work/Macabram/Macabram/pages/dashboard/home.vue?macro=true";
import { default as myaccountTOvmPHjVWtMeta } from "/home/runner/work/Macabram/Macabram/pages/dashboard/myaccount.vue?macro=true";
import { default as myordersAlKR8unEPIMeta } from "/home/runner/work/Macabram/Macabram/pages/dashboard/myorders.vue?macro=true";
import { default as _91id_93IJt2IiOu0nMeta } from "/home/runner/work/Macabram/Macabram/pages/dashboard/order/[id].vue?macro=true";
import { default as dashboardkIJMKehjI7Meta } from "/home/runner/work/Macabram/Macabram/pages/dashboard.vue?macro=true";
import { default as _91token_93KuE22gjsCtMeta } from "/home/runner/work/Macabram/Macabram/pages/forgot-password/[token].vue?macro=true";
import { default as indexzG0PHy60YmMeta } from "/home/runner/work/Macabram/Macabram/pages/forgot-password/index.vue?macro=true";
import { default as indextJfOhpfjTgMeta } from "/home/runner/work/Macabram/Macabram/pages/index.vue?macro=true";
import { default as legalE3hrn5uPSuMeta } from "/home/runner/work/Macabram/Macabram/pages/legal.vue?macro=true";
import { default as login605udLDnhVMeta } from "/home/runner/work/Macabram/Macabram/pages/login.vue?macro=true";
import { default as logoutZblxCqjPkCMeta } from "/home/runner/work/Macabram/Macabram/pages/logout.vue?macro=true";
import { default as maintenanceLU9LRzO51FMeta } from "/home/runner/work/Macabram/Macabram/pages/maintenance.vue?macro=true";
import { default as registerkK83UEcUUHMeta } from "/home/runner/work/Macabram/Macabram/pages/register.vue?macro=true";
import { default as indexB5SRHHmHBhMeta } from "/home/runner/work/Macabram/Macabram/pages/shop/index.vue?macro=true";
import { default as canceledFilVZxGnvhMeta } from "/home/runner/work/Macabram/Macabram/pages/shop/order/canceled.vue?macro=true";
import { default as index7eXY92rXBoMeta } from "/home/runner/work/Macabram/Macabram/pages/shop/order/index.vue?macro=true";
import { default as successAXrNTj0kXsMeta } from "/home/runner/work/Macabram/Macabram/pages/shop/order/success.vue?macro=true";
import { default as _91id_93_45_91name_93abnRflPxg0Meta } from "/home/runner/work/Macabram/Macabram/pages/shop/product/[id]-[name].vue?macro=true";
import { default as testU7nUa35NGjMeta } from "/home/runner/work/Macabram/Macabram/pages/test.vue?macro=true";
import { default as verify_emailQZF8G5wSqTMeta } from "/home/runner/work/Macabram/Macabram/pages/verify_email.vue?macro=true";
export default [
  {
    name: "account_deleted",
    path: "/account_deleted",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/account_deleted.vue")
  },
  {
    name: adminkFAv9K5WigMeta?.name,
    path: "/admin",
    meta: adminkFAv9K5WigMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin.vue"),
    children: [
  {
    name: "admin-categories",
    path: "categories",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/categories.vue")
  },
  {
    name: "admin",
    path: "",
    component: () => createClientPage(() => import("/home/runner/work/Macabram/Macabram/pages/admin/index.client.vue"))
  },
  {
    name: "admin-order-id",
    path: "order/:id()",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/order/[id].vue")
  },
  {
    name: "admin-order-manual-complete",
    path: "order/manual/complete",
    meta: completepyMcAqyl79Meta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/order/manual/complete.vue")
  },
  {
    name: "admin-order-manual",
    path: "order/manual",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/order/manual/index.vue")
  },
  {
    name: "admin-orders",
    path: "orders",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/orders.vue")
  },
  {
    name: "admin-products",
    path: "products",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/products.vue")
  },
  {
    name: "admin-settings",
    path: "settings",
    component: () => createClientPage(() => import("/home/runner/work/Macabram/Macabram/pages/admin/settings.client.vue"))
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/users.vue")
  },
  {
    name: "admin-variations",
    path: "variations",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/variations.vue")
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardkIJMKehjI7Meta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-home",
    path: "home",
    meta: homeUrYHCwEvyyMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/dashboard/home.vue")
  },
  {
    name: "dashboard-myaccount",
    path: "myaccount",
    meta: myaccountTOvmPHjVWtMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/dashboard/myaccount.vue")
  },
  {
    name: "dashboard-myorders",
    path: "myorders",
    meta: myordersAlKR8unEPIMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/dashboard/myorders.vue")
  },
  {
    name: "dashboard-order-id",
    path: "order/:id()",
    meta: _91id_93IJt2IiOu0nMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/dashboard/order/[id].vue")
  }
]
  },
  {
    name: "forgot-password-token",
    path: "/forgot-password/:token()",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/forgot-password/[token].vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/forgot-password/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/legal.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/logout.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/maintenance.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/register.vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/shop/index.vue")
  },
  {
    name: "shop-order-canceled",
    path: "/shop/order/canceled",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/shop/order/canceled.vue")
  },
  {
    name: "shop-order",
    path: "/shop/order",
    meta: index7eXY92rXBoMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/shop/order/index.vue")
  },
  {
    name: "shop-order-success",
    path: "/shop/order/success",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/shop/order/success.vue")
  },
  {
    name: "shop-product-id-name",
    path: "/shop/product/:id()-:name()",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/shop/product/[id]-[name].vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/test.vue")
  },
  {
    name: "verify_email",
    path: "/verify_email",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/verify_email.vue")
  }
]